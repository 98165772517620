//////////////////////////////////////////////////////////////////////////////////////////
//
// extensionUtils
// Group all functions that shared between popup/extension components
//
//////////////////////////////////////////////////////////////////////////////////////////

import { useState, useEffect, useRef } from "react"
import { copyToClip } from "../../actions/generalUtils"

import { t } from "i18next"

const blackList = [
	"meet.google.com",
	"mail.google.com",
	"docs.google.com",
	"drive.google.com",
	"apps.google.com",
	"chat.google.com",
	"contacts.google.com",
	"play.google.com",
	"apis.google.com",
	"accounts.google.com",
	"figma.com",
	"localhost:3000",
	"inexweb.fr",
	"inexvision.operations.eu.dynamics.com",
	"ieo-preprod01.sandbox.operations.dynamics.com",
	"ieo-proto03.sandbox.operations.eu.dynamics.com",
	"ieo-proto02.sandbox.operations.eu.dynamics.com",
	"ieo-formation01.sandbox.operations.eu.dynamics.com",
	"ieo-oneversion.sandbox.operations.dynamics.com",
	"mig.sandbox.operations.dynamics.com",
	"axereal-uat.sandbox.operations.dynamics.com",
	"reference.sandbox.operations.dynamics.com",
	"form.sandbox.operations.eu.dynamics.com",
	"rec.sandbox.operations.eu.dynamics.com",
]

// Open extension page
export function openExt(message) {
	try {
		const _devMode = window.location.href.includes("localhost:")

		if (_devMode) {
			window.open("http://localhost:3000", "_blank")
			return null
		} else {
			/* eslint-disable no-undef */
			// Actually this condition may be 100% unuseful
			if (message === "loginOnPopup") {
				setTimeout(() => {
					window.close()
					if (!chrome?.runtime) {
						browser.runtime.sendMessage(browser.runtime.id, {
							message: "redirectToLogin",
						})
					} else {
						chrome.runtime.sendMessage(chrome.runtime.id, {
							message: "redirectToLogin",
						})
					}
				}, 5000) // close popup after first toaster
			} else {
				if (!chrome?.runtime) {
					browser.runtime.sendMessage(browser.runtime.id, {
						message: "redirectToExt",
					})
				} else {
					chrome.runtime.sendMessage(chrome.runtime.id, {
						message: "redirectToExt",
					})
				}

				setTimeout(() => {
					window.close()
				}, 100)

				return
			}
		}
	} catch (e) {
		console.log("error", e)
	}
}

// Communcation with background functions$
// -> Prod : using chrome sendMessage
// -> Dev : communication by a customEvent which will be catched by content script in builded part and redirected to bg
export function callBackground(payload, cb) {
	/* eslint-disable no-undef */
	function fallback() {
		let event = new CustomEvent("LScomScriptCall", { detail: payload })
		document.dispatchEvent(event)

		return document.addEventListener("LScomScriptReturn", (req) => {
			//console.log("LScomScriptReturn listener : ", req)
			if (cb) return cb(req.detail)
			else return
		})
	}

	try {
		if (!chrome.runtime) {
			fallback()
		} else {
			//console.log("callBackground ok", payload)
			return chrome.runtime.sendMessage(
				chrome.runtime.id,
				payload,
				cb ? cb : undefined,
			)
		}
	} catch (e) {
		fallback()
	}
}

// Generate arbitrary proprietary footprint of a dom element
export function getObjectHash(item) {
	let ancestors = getAllAncestors(item)
	let hash = ""

	ancestors.forEach((item, i) => {
		if (i < ancestors.length - 1) {
			hash += `->${item.tagName}${item.id ? "#" + item.id : ""}${
				false && item.className ? "." + item.className : ""
			}`
		} else {
			hash +=
				"->INPUT" +
				getCombinedAttributes(item, [
					"aria-errormessage",
					"aria-label",
					"style",
					"type",
					"class",
					"id",
					"value",
					"placeholder",
					"disabled",
					"autocomplete",
				])
		}
	})

	return hash.replace(" ", "")
}

// Retrieves all ancestors until #html top
export function getAllAncestors(target) {
	let a = target
	let els = []
	while (a) {
		els.unshift(a)
		a = a.parentNode
	}
	return els
}

// Climb ancestors of targets to find first common parent
export function getCommonAncestors(targets) {
	if (targets.length < 2) return
	let i
	let nodes = [].slice.call(targets, 1)

	//tf ?
	rocking: while ((targets[0] = targets[0].parentNode)) {
		i = nodes.length
		while (i--) {
			if (
				(targets[0]["compareDocumentPosition"](nodes[i]) & 0x0010) !==
				0x0010
			)
				continue rocking
		}
		return targets[0]
	}
	return null
}

// Check if a given string respects given password policy
export function passRespectPolicy(credential, policy) {
	let respected = true

	if (policy && Object.keys(policy).length > 0) {
		let mins = [
			"a",
			"b",
			"c",
			"d",
			"e",
			"f",
			"g",
			"h",
			"i",
			"j",
			"k",
			"l",
			"m",
			"n",
			"o",
			"p",
			"q",
			"r",
			"s",
			"t",
			"u",
			"v",
			"w",
			"x",
			"y",
			"z",
		]
		let majs = [
			"A",
			"B",
			"C",
			"D",
			"E",
			"F",
			"G",
			"H",
			"I",
			"J",
			"K",
			"L",
			"M",
			"N",
			"O",
			"P",
			"Q",
			"R",
			"S",
			"T",
			"U",
			"V",
			"W",
			"X",
			"Y",
			"Z",
		]
		let nums = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
		let specs = [
			"~",
			"!",
			"@",
			"#",
			"$",
			"%",
			"^",
			"&",
			"*",
			"(",
			")",
			"-",
			"_",
			"=",
			"+",
			"[",
			"]",
			"{",
			"}",
			";",
			":",
			",",
			".",
			"<",
			">",
			"/",
			"?",
			"|",
		]

		if (
			policy.hasLowercaseLetter &&
			!mins.some((v) => credential.includes(v))
		)
			respected = false
		if (
			policy.hasCapitalLetter &&
			!majs.some((v) => credential.includes(v))
		)
			respected = false
		if (policy.hasNumber && !nums.some((v) => credential.includes(v)))
			respected = false
		if (policy.hasSpecialChar && !specs.some((v) => credential.includes(v)))
			respected = false
		if (
			credential.length < policy.minNbChar ||
			credential.length > policy.maxNbChar
		)
			respected = false
	}

	return respected
}

// Password generation framer
export function generatePass(propsPolicy = null, _copyToClip = true) {
	return new Promise(async (resolve) => {
		try {
			let shouldUpdateMinNbChar = 0
			const policy = {
				length: propsPolicy
					? propsPolicy?.id === 0
						? propsPolicy.maxNbChar
						: propsPolicy.minNbChar
					: 16,
				maxNbChar: propsPolicy ? propsPolicy.maxNbChar : 256,
				minNbChar: propsPolicy ? propsPolicy.minNbChar : 0,
				hasLowercaseLetter: propsPolicy
					? propsPolicy.hasLowercaseLetter
					: true,
				hasCapitalLetter: propsPolicy
					? propsPolicy.hasCapitalLetter
					: true,
				hasNumber: propsPolicy ? propsPolicy.hasNumber : true,
				hasSpecialChar: propsPolicy ? propsPolicy.hasSpecialChar : true,
			}

			for (const [key, value] of Object.entries(policy)) {
				if (
					[
						"hasLowercaseLetter",
						"hasCapitalLetter",
						"hasNumber",
						"hasSpecialChar",
					].includes(key) &&
					value
				) {
					shouldUpdateMinNbChar++
				}
			}

			if (shouldUpdateMinNbChar > policy?.length) {
				policy.length = shouldUpdateMinNbChar
			}

			let credential = await credentialGenerator(policy)

			// After generating a password check if it respects given policy
			// Mathematically with random generation it is possible that a password
			// miss to includes a type of char
			let rewind = !passRespectPolicy(credential, policy)

			if (rewind) {
				return resolve(generatePass(propsPolicy))
			} else {
				if (_copyToClip)
					copyToClip(
						credential,
						"popup.clipboard.copyGeneratedPassword",
						true,
						"popup.clipboard.copyPasswordTitle",
					)
				return resolve(credential)
			}
		} catch (error) {
			console.log(error, "error:176")
		}
	})
}

export function removeLastSawEntry(targetId) {
	let _recentPass = localStorage.getItem("LSlastSaw")

	if (_recentPass?.length) {
		_recentPass = JSON.parse(_recentPass)
		_recentPass = _recentPass.filter((x) => x.id != targetId)

		return localStorage.setItem("LSlastSaw", JSON.stringify(_recentPass))
	} else return
}

// Generate random string for a given policy
function credentialGenerator(policy) {
	return new Promise((resolve) => {
		let pass = ""
		let a = ""
		let pwdLength = policy.length ? policy.length : 12

		if (policy && Object.keys(policy).length > 0) {
			if (policy.hasCapitalLetter) {
				a += "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
			}
			if (policy.hasLowercaseLetter) {
				a += "abcdefghijklmnopqrstuvwxyz"
			}
			if (policy.hasNumber) {
				a += "123456789012345678901234567890"
			}
			if (policy.hasSpecialChar) {
				a += "~!@#$%^&*()-_=+[]{};:,.<>/?|"
			}

			pwdLength = policy.length
		} else {
			a +=
				"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789012345678901234567890~!@#$%^&*()-_=+[]{};:,.<>/?|"
		}

		for (let x = 0; x < pwdLength; x++) {
			let i = Math.floor(Math.random() * a.length)
			pass += a.charAt(i)
		}

		resolve(pass)
	})
}

export const capitalize = (s) => (s && s[0]?.toUpperCase() + s.slice(1)) || ""

// Retrieves only site name
export function getNameFromDomain(domain) {
	if (!domain.length) return domain

	try {
		const domainUrl = new URL(domain)

		return domainUrl.hostname
	} catch (e) {
		return domain
	}
}

export function getNameFromDomain2(url) {
	if (!url) return
	if (!url.includes("http")) return t("popup.siteUnsupported")

	let result
	let match

	if (
		(match = url.match(
			/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im,
		))
	) {
		result = match[1]
		if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
			result = match[1]
		}
	}

	if (result === "null") {
		return t("popup.siteUnsupported")
	}

	/*console.log("before slice -> ", result)
	const dotPos = result.lastIndexOf(".")
	result = result.slice(0, dotPos)
	console.log("after slice -> ", result)*/

	return result
}

export function getDomain() {
	const original = document.domain
	const parts = window.location.hostname.split(".")
	let domain = parts.pop()

	while (parts.length) {
		domain = parts.pop() + "." + domain
		try {
			document.domain = domain // we found the eTLD+1
			break
		} catch (e) {
			// eTLDs and eTLD fragments fail
		}
	}

	// reset before returning
	try {
		document.domain = original
	} catch (e) {}

	return domain
}

// Add keywords to avoid in guess for login / pass in attributes detection
function parentIncludesExceptions(str) {
	let parentExclusions = [
		"SEARCH",
		"CONTACT",
		"QUANTITY",
		"POSTCODE",
		"BILLING",
		"SHIPPING",
		"TRACK",
		"NEWS",
		"POSTAL",
		"ZIP",
		"COUPON",
		"GIFT",
		"DISCOUNT",
		"REDUCTION",
		"PROMO",
		"TVA",
		"TAX",
		"TICKET",
		"CVV",
		"CVC",
		"CHECKOUT",
		"PAY",
		"DOWNLOAD",
		"HELP",
		"PUNCHER",
	]
	//console.log(str)

	if (parentExclusions.some((v) => str.includes(v))) return true
	else return false
}

// Add keywords to avoid in guess for login / pass in attributes detection
function includesExceptions(str) {
	let strExclusions = [
		"TEL",
		"SEARCH",
		"CONTACT",
		"QUANTITY",
		"POSTCODE",
		"BILLING",
		"SHIPPING",
		"TRACK",
		"NEWS",
		"POSTAL",
		"ZIP",
		"COUPON",
		"GIFT",
		"DISCOUNT",
		"REDUCTION",
		"PROMO",
		"TVA",
		"TAX",
		"TICKET",
		"CVV",
		"CVC",
		"CHECKOUT",
		"PAY",
		"DOWNLOAD",
		"HELP",
		"PUNCHER",
		"THIRDPARTY",
	]

	let foundExclusions = 0

	strExclusions.forEach((v) => {
		if (str.includes(v)) {
			foundExclusions++
		}
	})

	if (foundExclusions > 0) {
		if (foundExclusions === 1) {
			// Special exclusions for same letters combinations
			if (str.includes("TVA") && str.includes("INPUTVALUE")) {
				return false
			} else return true
		} else return true
	} else return false
}

function attributesFilter(a) {
	if (a.name === "value" || a.name === "type") return false
	else return true
}

// Get all attributes of a field and guess if it's an password field
// By searching for some keywords
export function considerAsPwdField(elem, watchForExclusions = true) {
	// Here we combine all attributes data and search for keyword to consider input as auto-completable
	let combinedAttributes = ""
	let attributeNodeArray = [...elem.attributes]

	attributeNodeArray = attributeNodeArray.filter(attributesFilter)
	attributeNodeArray.reduce((attrs, attribute) => {
		return (combinedAttributes += ` ${attribute.value}`)
	}, {})

	//console.log("PWD ATTRIBUTES", combinedAttributes)

	if (combinedAttributes.length) {
		// Later it can be improved by count occurencies over just search for one
		if (
			combinedAttributes.toUpperCase().includes("PASSWORD") ||
			combinedAttributes.toUpperCase().includes("PWD") ||
			combinedAttributes.toUpperCase().includes("SECRET") ||
			combinedAttributes.toUpperCase().includes("CREDENTIAL") ||
			combinedAttributes.toUpperCase().includes("CODE")
		) {
			if (!watchForExclusions) return true

			if (!includesExceptions(combinedAttributes.toUpperCase()))
				return true
			else return false
		} else return false
	} else return false
}

// Get all attributes of a field and guess if it's an login field
// By searching for some keywords
export function considerAsUsernameField(
	elem,
	watchForExclusions = true,
	extendedAcceptation = false,
) {
	let combinedAttributes = ""
	let attributeNodeArray = [...elem.attributes]

	attributeNodeArray = attributeNodeArray.filter(attributesFilter)
	attributeNodeArray.reduce((attrs, attribute) => {
		return (combinedAttributes += ` ${attribute.value}`)
	}, {})

	//console.log("-USER-ATTRIBUTES-->", combinedAttributes.toUpperCase())
	//console.log("isIncludes exclusions -> ", includesExceptions(combinedAttributes.toUpperCase()))

	if (combinedAttributes.length) {
		// Later it can be improved by count occurencies over just search for one
		if (
			combinedAttributes.toUpperCase().includes("EMAIL") ||
			combinedAttributes.toUpperCase().includes("LOGIN") ||
			combinedAttributes.toUpperCase().includes("USERID") ||
			combinedAttributes.toUpperCase().includes("IDENTIFI") ||
			combinedAttributes.toUpperCase().includes("USERNAME") ||
			(extendedAcceptation &&
				combinedAttributes.toUpperCase().includes("USER")) ||
			(extendedAcceptation &&
				combinedAttributes.toUpperCase().includes("FORM"))
			//combinedAttributes.toUpperCase().includes("TEL")
			//combinedAttributes.toUpperCase().includes("USER") ||
			//combinedAttributes.toUpperCase().includes("ID")
			//combinedAttributes.toUpperCase().includes("PHONE")
		) {
			//console.log(" watchForExclusions -> user -> ", watchForExclusions)

			if (!watchForExclusions) return true

			if (!includesExceptions(combinedAttributes.toUpperCase())) {
				// If no exclusions found in input attributes
				return true
			} else if (
				elem.id?.length &&
				(elem.id.toUpperCase().includes("EMAIL") ||
					elem.id?.toUpperCase().includes("LOGIN") ||
					elem.id.toUpperCase().includes("USERID") ||
					elem.id.toUpperCase().includes("IDENTIFI") ||
					elem.id.toUpperCase().includes("USERNAME"))
			) {
				// If element attributes contains exceptions we trying to give privilege to id element
				return true
			} else return false
		} else return false
	} else return false
}

export function getCSSstyleOfElem(elem, property) {
	if (!elem) return
	let style = window.getComputedStyle(elem)
	return style ? style.getPropertyValue(property) : null
}

// Climb until highest ancestor and check css visibility
export function allAncestorsFullyVisible(
	elem,
	checkForParentExclusions = false,
) {
	let hasNotDisplayNone = true
	let parentNumber = 0

	try {
		while (elem?.tagName) {
			if (checkForParentExclusions) parentNumber++

			if (
				hasNotDisplayNone &&
				(getCSSstyleOfElem(elem, "display") === "none" ||
					(getCSSstyleOfElem(elem, "max-height") === "0px" &&
						getCSSstyleOfElem(elem, "min-height") &&
						parseInt(
							getCSSstyleOfElem(elem, "min-height").slice(0, -2),
						) <= 0) ||
					(getCSSstyleOfElem(elem, "max-height") === "0px" &&
						getCSSstyleOfElem(elem, "height") &&
						parseInt(
							getCSSstyleOfElem(elem, "height").slice(0, -2),
						) <= 0) ||
					getCSSstyleOfElem(elem, "max-width") === "0px" ||
					getCSSstyleOfElem(elem, "visibility").includes("hidden"))
			) {
				hasNotDisplayNone = false
			}

			if (
				checkForParentExclusions &&
				parentNumber < 4 &&
				hasNotDisplayNone
			) {
				let combinedAttributes = ""
				let attributeNodeArray = [...elem.attributes]

				attributeNodeArray = attributeNodeArray.filter(
					(a) => a.name !== "value",
				)
				attributeNodeArray.reduce((attrs, attribute) => {
					return (combinedAttributes += ` ${attribute.value}`)
				}, {})

				if (parentIncludesExceptions(combinedAttributes))
					hasNotDisplayNone = false
			}

			if (elem.tagName === "HTML") elem = undefined
			else elem = elem.parentNode
		}
	} catch (e) {}

	return hasNotDisplayNone
}

// Climb until highest ancestor and check display none style on each
export function allAncestorsDisplayed(elem) {
	let hasNotDisplayNone = true
	while (elem?.tagName) {
		if (getCSSstyleOfElem(elem, "display") === "none")
			hasNotDisplayNone = false

		if (elem.tagName === "HTML") elem = undefined
		else elem = elem.parentNode
	}
	return hasNotDisplayNone
}

// Treat iframe element and guess if it's visible to the user
export function considerAsVisibleIframe(elem) {
	// Cases to avoid directly, like some domains in src, src is being filtered
	// later from our tests to prevent some missunderstanding, in complex urls
	if (
		!elem.src ||
		elem.src.includes("googlesyndication.com") ||
		elem.src.includes("google.com/recaptcha") ||
		elem.title.includes("reCAPTCHA")
	) {
		return false
	}

	let combinedAttributes = ""
	let attributeNodeArray = [...elem.attributes]

	attributeNodeArray = attributeNodeArray.filter((a) => a.name !== "src")
	attributeNodeArray.reduce((attrs, attribute) => {
		return (combinedAttributes += attribute.value)
	}, {})

	// Drop if element is a "pixel"
	// -> is not (/will not become) an interface
	if (
		/*(!elem.width && !elem.height) ||*/ parseInt(elem.width) <= 5 ||
		parseInt(elem.height) <= 5
	)
		return false

	if (combinedAttributes.length) {
		if (
			combinedAttributes.includes("google_ads_iframe")
			//|| combinedAttributes.includes("display:none") seems OK by not working on tripadvisor login popup...
			//combinedAttributes.toUpperCase().includes("USER") ||
			//combinedAttributes.toUpperCase().includes("ID")
		) {
			return false
		} else return true
	}
}

// Combine all attributes of an dom element
// Accept exclusions of some attribute keys (in props )
export function getCombinedAttributes(target, excludeAttributes = []) {
	let combinedAttributes = ""
	let attributeNodeArray = [...target.attributes]

	attributeNodeArray.reduce((attrs, attribute) => {
		return (combinedAttributes += excludeAttributes.includes(attribute.name)
			? ""
			: attribute.value)
	}, {})
	return combinedAttributes.replace(" ", "")
}

// Get document coordinates of the element -> relative to screen
export function getCoords(elem) {
	let box = elem.getBoundingClientRect()
	return {
		top: box.top + window.pageYOffset,
		right: box.right + window.pageXOffset,
		bottom: box.bottom + window.pageYOffset,
		left: box.left + window.pageXOffset,
	}
}

const DEFAULT_OPTIONS = {
	config: { attributes: true, childList: true, subtree: true },
}

export function useMutationObservable(targetEl, cb, options = DEFAULT_OPTIONS) {
	// Trigger a callback function whenever the targetEl is mutated
	const [observer, setObserver] = useState(null)

	useEffect(() => {
		// console.log(targetEl, "targetEl")
		if (!targetEl) {
			console.warn(
				`[LS] You must provide a valid DOM element to observe, instead you've provided ${targetEl}`,
			)
		} else {
			if (!cb || typeof cb !== "function") {
				console.warn(
					`[LS] You must provide a valid callback function, instead you've provided ${cb}`,
				)
			} else {
				const obs = new MutationObserver(cb)
				setObserver(obs)
			}
		}
	}, [targetEl, cb, options, setObserver])

	useEffect(() => {
		// console.log(targetEl, "targetEl")
		if (!observer || !targetEl) {
			console.warn(
				`You must provide a valid DOM element to observe, instead you've provided ${targetEl}`,
			)
		} else {
			const { config } = options

			try {
				observer.observe(targetEl, config)
			} catch (e) {
				console.error("[LS] ", e)
			}

			return () => {
				if (observer) {
					observer.disconnect()
				}
			}
		}
	}, [observer, options])
}

//======================================================================================================= UNUSED CONTENT BELOW =======================================================================================================//
// After a lot of time spend on working with various extensions storage and a polyfill package, it has been decided to only use localStorage as almost no contentScripts are used in the new extension (the only one doesn't access any storage)
// Since the majority of these functions work with various extensions storage, they now don't serve any purpose.

/*global chrome*/
/*global browser*/

// Get the type of extension we have. It varies depending on wether we are using chrome, firefox or edge and, for firefox and edge, if we are working with a build version or 'npm start'.
export function getExtensionType() {
	let result

	if (window.browser) {
		result = "firefoxEdge"
	} else if (window?.chrome?.storage) {
		//console.log("window", window)
		result = "chrome"
	} else {
		result = "webApp"
	}

	return result
}

// Get the value stored in the adequate extension storage.
export async function getExtensionItem(key) {
	return new Promise(async (resolve) => {
		const extensionType = getExtensionType()
		//console.log("Extension type used : ", extensionType)
		let result = null

		if (extensionType === "chrome") {
			// Chrome API su... is a little bit annoying as it uses a callback system. Therefore, we have to add a promise that will be solved in the callback to return the value.
			chrome.storage.local.get(key, (searchResult) => {
				resolve(searchResult)
				return
			})
		} else if (extensionType === "firefoxEdge") {
			result = await browser.storage.local.get(key)
			resolve(result)
			return
		} else if (extensionType === "webApp") {
			result = window.localStorage.getItem(key)
			resolve(JSON.parse(result))
			return
		}

		return null
	})
}

// Set a key to a specific value in the adequate extension storage.
export function setExtensionItem(key, value) {
	try {
		const extensionType = getExtensionType()
		let result = null

		if (extensionType === "chrome") {
			// Chrome API is still Chrome API (amazing, isn't it ?), it means we must still use a promise to make sure the value is updated before making anything.
			return new Promise(async (resolve) => {
				try {
					chrome.storage.local.set({ [key]: value }, () => {
						return resolve(value)
					})
				} catch (error) {
					console.warn("setExtensionItem error", error)
				}
			})
		} else if (extensionType === "firefoxEdge") {
			return new Promise(async (resolve) => {
				try {
					result = await browser.storage.local.set({ key: value })
					return resolve(result)
				} catch (error) {
					/* console.warn("setExtensionItem error", error) */
				}
			})
		} else if (extensionType === "webApp") {
			return new Promise(async (resolve) => {
				const stringifiedValue = JSON.stringify(value)
				return resolve(
					window.localStorage.setItem(key, stringifiedValue),
				)
			})
		}

		return result
	} catch (e) {
		return // console.warn("Failed to save extension item.")
	}
}

export function renamePerso(category, translationCB) {
	if (category === "Perso") {
		return translationCB("popup.list.categoryPerso")
	} else {
		return category
	}
}

export function usePrevious(value) {
	const ref = useRef()

	useEffect(() => {
		ref.current = value //assign the value of ref to the argument
	}, [value]) //this code will run when the value of 'value' changes

	return ref.current //in the end, return the current ref value.
}

export default usePrevious

export function getStorageSync(key, cb, defaultVal = undefined) {
	const pageOrigin =
		window.location !== window.parent.location
			? document.referrer.replace(/\/$/, "")
			: document.location.origin

	// Initial function for input detection
	try {
		return chrome.storage.sync.get([pageOrigin], (res) => {
			if (res?.[pageOrigin]?.[key] === undefined) cb(defaultVal)
			else cb(res?.[pageOrigin]?.[key])
		})
	} catch (e) {
		// local dev fallback
		return console.warn("can't access the chrome storage in local")
	}
}

export function chromeSendMessage(message, payload, options, cb, targetTab) {
	try {
		if (!chrome?.tabs?.query) return

		if (targetTab?.id) {
			//console.log("targetTab -> ", targetTab)

			return chrome.tabs.sendMessage(
				targetTab.id,
				{ message, ...payload },
				options,
				cb,
			)
		} else {
			return chrome.tabs.query(
				{ active: true, currentWindow: true },
				(tabs) => {
					const currTab = tabs[0]
					//console.log("currTab -> ", currTab)

					if (currTab)
						chrome.tabs.sendMessage(
							currTab.id,
							{ message, ...payload },
							options,
							cb,
						)
					return
				},
			)
		}
	} catch (e) {}
}

export function isUrlBlackList(url) {
	let result = false

	blackList.forEach((key) => {
		if (url.includes(key)) {
			return (result = true)
		}
	})
	return result
}

export function useSyncStorage(
	key,
	defaultValue,
	defaultMem,
	url,
	reload = false,
	deleteValues = false,
) {
	const [value, setValue] = useState()
	const [memValue, setMemValue] = useState(defaultMem)

	let _devMode = window.location.href.includes("localhost:")
	let _otherTabs = !url.includes("http")

	/*useEffect(() => {
		console.log("%cmemValue", "background-color: yellow;color: black;", memValue, key)
		console.log("defaultMem", defaultMem);
		// syncStrorageCommunication("GET")
	}, [])*/

	useEffect(() => {
		if (deleteValues) return syncStrorageCommunication("DEL")
	}, [deleteValues])

	useEffect(() => {
		if (!value) return
		if (JSON.stringify(value) === JSON.stringify(memValue)) return

		//console.log("%cmemValue", "background-color: orange;color: black;", memValue, key, value)
		if (memValue === null) syncStrorageCommunication("GET")
		else syncStrorageCommunication("SET")
	}, [value])

	useEffect(() => {
		if (JSON.stringify(value) === JSON.stringify(memValue)) return
		//console.log("%cmemValue", "background-color: red;color: black;", memValue, key, value)

		if (!memValue) setValue(defaultValue)
		else setValue(memValue)
	}, [memValue])

	// This function manages the communication with the chrome sync storage
	// sending to the background the type of operation it must perform, with GET, SET, DEL.
	function syncStrorageCommunication(method) {
		if (value === undefined) return
		if (_devMode || _otherTabs) return
		//console.log("________________");
		//console.log("method HOOK", method, value, key);

		function cb(memStates) {
			// const destruc = typeof memStates === "object" ? memStates?.value : memStates
			const destruc = memStates?.value ? memStates?.value : memStates
			//console.log("memStates", memStates)
			//console.log("___________ destruc", method, value, key)

			// Even if the callback does not return anything, we change the state of memValue to false to trigger the useEffect
			if ((method === "GET" && destruc === null) || method === "DEL") {
				return setMemValue(false)
			} else if (method === "GET" && destruc === undefined) {
				return setMemValue(false)
			} else if (JSON.stringify(destruc) !== JSON.stringify(memValue)) {
				return setMemValue(destruc)
			}
		}

		return callBackground(
			{
				message: "LSchromeSyncStorage",
				method,
				values: typeof value === "object" ? { ...value } : { value },
				key,
				reload,
			},
			cb,
		)
	}

	return { value, setValue, memValue }
}

export function handleLinkPasswordData(e, password) {
	let extensionId
	let extensionNavigator

	if (typeof chrome !== "undefined" && chrome.runtime && chrome.runtime.id) {
		extensionId = chrome.runtime.id
	} else return

	const userAgent = navigator.userAgent

	if (userAgent.includes("Chrome")) {
		extensionNavigator = "chrome-extension"
	} else if (userAgent.includes("Firefox")) {
		extensionNavigator = "moz-extension"
	} else if (userAgent.includes("Edge")) {
		extensionNavigator = "ms-browser-extension"
	} else return

	const root =
		password?.categoryMainId === 0 && password?.categoryId === 0
			? "Personal"
			: password?.categoryMainId === 0 && password?.categoryId !== 0
			? "Personal/Category"
			: "Shared/Category"

	let link

	if (extensionNavigator === "moz-extension") {
		link = `${browser.runtime.getURL(
			"index.html#/LockPass/",
		)}${root}/Password/${password.id}`

		browser.tabs.create({ url: link }, () => {
			window.close()
		})
	} else {
		link = `${extensionNavigator}://${extensionId}/index.html#/LockPass/${root}/Password/${password.id}`

		e.stopPropagation()
		e.preventDefault()
		return window.open(link, "_blank")
	}
}
